<template>
<div class="timeline timeline-5">
  <div
    class="timeline-items scroll-div scrollbar-primary"
    v-infinite-scroll="infiniteHandler" infinite-scroll-distance="200" :infinite-scroll-disabled="busy"
  >
    <ItemLog v-for="item in items" :item="item" :key="item.uuid" />
    <div v-show="busy" class="text-center">
      <b-spinner variant="secondary" class="align-middle" />
    </div>
  </div>
</div>
</template>

<script>
import Repo from '@/core/repository/company/logsRepository'
import infiniteScroll from 'vue-infinite-scroll'
import qs from 'qs'
import ItemLog from './item'

export default {
  name: 'AppLogs',
  directives: { infiniteScroll },
  components: { ItemLog },
  data() {
    return {
      more: true,
      busy: false,
      items: [],
      params: {
        pagination: {
          limit: 20,
          page: 1,
        },
      },
    }
  },
  watch: {
    '$route.query.dateRange': {
      handler() {
        // ждем готовности от бека
        this.reFetch()
      },
      deep: true,
    },
  },
  methods: {
    reFetch() {
      this.more = true
      this.busy = false
      this.params.pagination = {
        limit: 20,
        page: 1,
      }
      this.items = []
      this.infiniteHandler()
    },
    infiniteHandler() {
      if (!this.more || this.busy) return
      this.busy = true
      let params = { ...this.params }
      // ждем готовности от бека
      if (this.$route.query.dateRange) {
        params = {
          ...this.params,
          ...qs.parse(this.$route.query.dateRange),
        }
      } else {
        return
      }
      Repo.list(params).then(({ data }) => {
        this.items = this.items.concat(data.payload.items)
        this.more = data.payload.pagination.current < data.payload.pagination.last
        if (this.more) this.params.pagination.page += 1
        this.busy = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .scroll-div{
    min-height: 40px; overflow-y:auto;
    max-height: 300px;
    width: 100%;
    margin: 0 auto;
  }
</style>
