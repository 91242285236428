<template>
<b-container fluid class="bv-example-row h-100">
  <template v-if="hasAccess">
    <Portal to="subheader">
      <div class="mt-lg-16">
        <KTSubheader />
      </div>
    </Portal>
    <b-row>
      <is-granted :attributes="[dashboardPermissions[0]]">
        <b-col cols="12" md="12" lg="4" class="p-0 py-3 p-md-3">
          <PlanCard class="h-100" />
        </b-col>
      </is-granted>
      <is-granted :attributes="[dashboardPermissions[1]]">
        <b-col cols="12" md="12" lg="8" class="p-0 py-3 p-md-3">
          <UserStats class="h-100" />
        </b-col>
      </is-granted>
    </b-row>
    <is-granted :attributes="[dashboardPermissions[2]]">
      <b-row>
        <b-col cols="12" class="p-0 py-3 p-md-3">
          <KTCard>
            <template #title>
              <div class="d-flex align-items-center flex-wrap mr-2">
                <!--begin::Title-->
                <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5 d-none d-sm-block">
                  {{ $t('DASHBOARD.LOGS_TITLE') }}</h5>
                <!--end::Title-->
              </div>
            </template>
            <template #body>
              <AppLogs />
            </template>
          </KTCard>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="p-0 pb-3 p-md-3">
          <branch-stats />
        </b-col>
      </b-row>
    </is-granted>
  </template>
  <AccessDisabled v-else />
</b-container>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import KTCard from '../../content/Card'
import AppLogs from '../../../components/app-logs/index'
import KTSubheader from '../../layout/subheader/Subheader'
import PlanCard from '../../../components/dashboard/PlanCard'
import UserStats from '../../../components/dashboard/UserStats'
import AccessDisabled from '../../../components/UI/AccessDisabled'
import BranchStats from '../../../components/dashboard/BranchStats.vue'

export default {
  name: 'Dashboard',
  components: {
    AccessDisabled,
    UserStats,
    PlanCard,
    KTSubheader,
    AppLogs,
    KTCard,
    BranchStats,
  },
  data() {
    return {
      dashboardPermissions: [
        'view_daily_plan_stats',
        'view_employee_order_stats',
        'view_logs',
      ],
    }
  },
  computed: {
    hasAccess() {
      return this.$store.getters.getPermissions.filter((x) => this.dashboardPermissions.includes(x.attribute)).length
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('PAGE.DASHBOARD') }])
  },
}
</script>
