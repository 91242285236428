<template>
<div class="d-flex flex-column h-100">
  <b-form class="form w-100 flex-fill" @submit.stop.prevent="onSubmit">
    <b-container fluid class="p-0">
      <b-row v-for="(f, index) in form" :key="f.uuid">
        <b-col cols="12">
          <b-form-group :label="$t('DATES.DAYS_OF_WEEK_ARRAY')[f.seqNum]" label-for="input-1">
            <InputForm
              id="input-1"
              v-model="$v.form.$each[index].sumByOrders.$model"
              :state="validateState('sumByOrders', index)"
              :placeholder="$t('PLACEHOLDER.VALUE')"
              trim
              @input="clearServerError('sumByOrders', index)"
              aria-describedby="input-1-feedback"
            />
            <b-form-invalid-feedback id="input-1-feedback" :class="{'d-block': validateState('sumByOrders', index) === false}">
              <template v-if="serverErrors.name">{{ serverErrors.name[0] }}</template>
              <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('DASHBOARD.PLAN_CARD.PLAN_VALUE') }) }}</template>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
  <b-button
    @click="onSubmit"
    :disabled="loading || $v.form.$anyError"
    class="btn-block d-flex align-items-center justify-content-center btn btn-light-success font-weight-bold btn-sm"
  >
    <b-spinner v-if="loading" small variant="light" />
    {{ $t('FORM.SUBMIT') }}
  </b-button>
  <div class="fake-element">
    <!--      IOS fix-->
  </div>
</div>
</template>

<script>
import Repo from '@/core/repository/company/dailyPlan'
import { validationMixin } from 'vuelidate'
import { required, numeric } from 'vuelidate/lib/validators'
import InputForm from '../forms-items/input'
import serverVuelidate from '../../mixins/serverVuelidate'

export default {
  name: 'PlanForm',
  components: {
    InputForm,
  },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      form: [],
      validations: {
        form: {
          $each: {
            sumByOrders: {
              required,
              numeric,
            },
          },
        },
      },
      loading: false,
    }
  },
  computed: {
    Repo() {
      return Repo
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      this.Repo.list().then(({ data }) => {
        this.form = data.payload
        this.loading = false
      })
    },
    clearServerError(key, index) {
      if (this.validations.form.$each[key] && this.validations.form.$each[key].serverErrors) {
        this.$delete(this.validations.form.$each[key], 'serverErrors')
      }
      this.$set(this.$v.form.$each[index], 'touched', true)
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true
      const patchPromises = []
      this.form.forEach((f, index) => {
        if (this.$v.form.$each[index] && this.$v.form.$each[index].touched) {
          patchPromises.push(this.Repo.patch(f.uuid, {
            sumByOrders: f.sumByOrders,
          }))
          this.$set(this.$v.form.$each[index], 'touched', false)
        }
      })
      Promise.all(patchPromises)
        .then(() => {
          this.$emit('updateData')
        })
        .finally(() => {
          this.loading = false
        })
    },
    validateState(name, index) {
      const { $dirty, $error } = this.$v.form.$each[index][name]
      return $dirty ? !$error : null
    },
  },
}
</script>

<style scoped>

</style>
