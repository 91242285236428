<template>
<div :class="classes" class="card-body">
  <div class="example">
    <div class="table-wrapper">
      <table class="table table-hover mb-0">
        <thead>
          <slot name="thead" />
        </thead>
        <tbody>
          <slot name="tbody" />
        </tbody>
        <tfoot>
          <slot name="tfoot" />
        </tfoot>
      </table>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'TableReport',
  props: {
    classes: {
      type: String,
      default: 'px-2 px-md-5 pt-2 pt-md-5',
    },
  },
}
</script>

<style scoped lang="scss">
    .table tr td {
        text-align: center;
    }
    .table-wrapper{
        overflow-x: auto;
        max-height: 600px;
        border: 1px solid #ebedf3;
        &::-webkit-scrollbar {
           width: 4px;
           height: 4px;
         }
        &::-webkit-scrollbar-track {
           background: #ebedf3;
         }
        &::-webkit-scrollbar-thumb {
           background: #5E6278;
         }
        scrollbar-color: #ebedf3 #5E6278;
        scrollbar-width: thin;
    }
    table thead th {
        text-align: center;
        vertical-align: middle;
        hyphens: none;
        font-weight: normal;
        background-color: #eaf9ff;
        border: 1px solid #d9dbe1;
        border-collapse: collapse;
        padding: 0.5rem;
    }
    table td  {
        padding: 0.6rem;
        background-color: #fdfdfd;
    }
    table {
        white-space: nowrap;
    }
    tbody {
        overflow-y:auto;
    }

    table {
        border-collapse: collapse;
    }
    table td, table th {
        border: 1px solid #ebedf3;
    }
    table tr:first-child th {
        border-top: 0;
    }
    table tr:last-child td {
        border-bottom: 0;
    }
    table tr td:first-child,
    table tr th:first-child {
        border-left: 0;
    }
    table tr td:last-child,
    table tr th:last-child {
        border-right: 0;
    }
</style>
