<template>
<div class="d-flex flex-column flex-root h-100">
  <div
    :style="`background-image: url(${backgroundImage});`"
    class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
  >
    <!--begin::Content-->
    <h1 class="font-weight-boldest text-dark-75 mt-15" style="font-size: 10rem">
      403
    </h1>
    <p class="font-size-h3 text-muted font-weight-normal">
      OOPS! You don't have access to any dashboard module
    </p>
    <!--end::Content-->
  </div>
</div>
</template>
<script>
export default {
  name: 'AccessDisabled',
  computed: {
    backgroundImage() {
      return `${process.env.BASE_URL}media/bg/error-bg.jpg`
    },
  },
}
</script>
