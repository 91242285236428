<template>
<div class="subheader py-2 py-lg-4 subheader-solid">
  <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
    <div class="d-flex align-items-center">
      <div
        class="btn btn-clean btn-sm font-weight-bold font-size-base mr-1"
        :class="{'active': isSelected('today')}"
        @click="selectRange('today')"
      >
        {{ $t('DATES.TODAY') }}
      </div>
      <div
        class="btn btn-clean btn-sm font-weight-bold font-size-base mr-1"
        :class="{'active': isSelected('month')}"
        @click="selectRange('month')"
      >
        {{ $t('DATES.MONTH') }}
      </div>
      <div
        class="btn btn-clean btn-sm font-weight-bold font-size-base mr-1"
        :class="{'active': isSelected('year')}"
        @click="selectRange('year')"
      >
        {{ $t('DATES.YEAR') }}
      </div>
      <DateRangePickerUI
        v-model="dateRange"
        @update="update"
      />
    </div>
  </div>
</div>
</template>

<script>

import qs from 'qs'
import merge from 'lodash/merge'
import DateRangePickerUI from '../../../components/UI/DateRangePickerUI'

const FORMAT = 'YYYY-MM-DD HH:mm:ss'

export default {
  name: 'KTSubheader',
  components: { DateRangePickerUI },
  data() {
    return {
      format: FORMAT,
      selectedRange: 'TODAY',
      dateRange: {
        period: {
          from: this.$moment().startOf('day').format(FORMAT),
          to: this.$moment().endOf('day').format(FORMAT),
        },
      },
      ranges: {
        today: {
          period: {
            from: this.$moment().startOf('day').format(FORMAT),
            to: this.$moment().endOf('day').format(FORMAT),
          },
        },
        month: {
          period: {
            from: this.$moment().startOf('month').format(FORMAT),
            to: this.$moment().endOf('month').format(FORMAT),
          },
        },
        year: {
          period: {
            from: this.$moment().startOf('year').format(FORMAT),
            to: this.$moment().endOf('year').format(FORMAT),
          },
        },
      },
    }
  },
  computed: {
    isSelected() {
      return (key) => {
        return this.$moment(this.dateRange.period.from)
          .startOf('day')
          .format(FORMAT) === this.$moment(this.ranges[key].period.from)
          .format(this.format) && this.$moment(this.dateRange.period.to)
          .endOf('day')
          .format(this.format) === this.$moment(this.ranges[key].period.to)
          .format(this.format)
      }
    },
  },
  mounted() {
    this.dateRange = this.$route.query.dateRange ? qs.parse(this.$route.query.dateRange) : this.dateRange
    this.onReplace()
  },
  methods: {
    update(val) {
      const date = {
        period: {
          from: '',
          to: '',
        },
      }
      date.period.from = this.$moment(val.startDate).startOf('day').format(this.format)
      date.period.to = this.$moment(val.endDate).endOf('day').format(this.format)
      this.dateRange = date
      this.onReplace()
    },
    selectRange(key) {
      this.dateRange = this.ranges[key]
      this.onReplace()
    },
    onReplace() {
      const queryOld = this.$route.query.dateRange ? qs.parse(this.$route.query.dateRange) : this.dateRange
      const queryNew = merge(queryOld, this.dateRange)
      const replaceRouteQuery = {
        ...this.$route.query,
        dateRange: qs.stringify(queryNew), 
      }
      if (JSON.stringify(this.$route.query) !== JSON.stringify(replaceRouteQuery)) {
        this.$router.replace({
          name: 'dashboardCompany',
          params: this.$route.params,
          query: replaceRouteQuery, 
        })
      }
    },
  },
}
</script>
