<template>
<div class="card card-custom card-stretch gutter-b">
  <div class="card-header border-0 pt-5">
    <h3 class="card-title font-weight-bolder">{{ $t('DASHBOARD.PLAN_CARD.TITLE') }}</h3>
  </div>
  <div class="card-body d-flex flex-column pt-0">
    <div class="flex-grow-1" style="position: relative;">
      <CircleGraph
        :value="plan.percentage"
      />
    </div>
    <div>
      <p class="text-center font-weight-normal font-size-lg pb-3">
        {{ plan.sumByOrders }} {{ $t('DASHBOARD.PLAN_CARD.OF') }} {{ plan.sumByOrdersPlanned }}
      </p>
      <b-button @click="active = true" class="btn-success btn-shadow-hover font-weight-bolder w-100 py-3">
        {{ $t('DASHBOARD.PLAN_CARD.SET_PLAN') }}
      </b-button>
    </div>
  </div>
  <quick-panel v-model="active">
    <template #title>{{ $t('DASHBOARD.PLAN_CARD.SET_PLAN') }}</template>
    <template #body>
      <PlanForm @updateData="fetchData" />
    </template>
  </quick-panel>
</div>
</template>

<script>
import Repo from '@/core/repository/company/dashboardStats'
import CircleGraph from '../UI/CircleGraph'
import PlanForm from './PlanForm'

export default {
  name: 'PlanCard',
  components: {
    CircleGraph,
    PlanForm, 
  },
  data() {
    return {
      active: false,
      plan: {
        sumByOrders: 0,
        sumByOrdersPlanned: 0,
        percentage: 0,
        date: new Date(),
        weekday: '',
      },
    }
  },
  computed: {
    Repo() {
      return Repo
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.Repo.getDailyPlan().then(({ data }) => {
        this.plan = data.payload.report.payload[0]
        this.active = false
      })
    },
  },
}
</script>

<style scoped>

</style>
