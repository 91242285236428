<template>
<div class="timeline-item">
  <!--begin::Icon-->
  <div class="timeline-media" :class="`bg-transparent`">
    <span class="svg-icon svg-icon-md ml-1" :class="`svg-icon-${setClass}`">
      <inline-svg :src="`/media/svg/icons/${setSvg}.svg`" />
    </span>
  </div>
  <!--end::Icon-->

  <!--begin::Info-->
  <div class="timeline-desc timeline-desc-light-primary pt-2">
    <span class="font-weight-bolder font-size-sm">
      <span class="text-dark-75 mr-3">{{ $moment(item.loggedAt).format(FORMAT_TIME) }}</span>
      <span class="text-muted">{{ $moment(item.loggedAt).format(FORMAT_DATE_LOG) }}</span>
    </span>
    <p class="font-weight-normal text-dark-50 pb-2">
      {{ item.action_trans }} {{ item.friendlyName }} - <span v-if="item.user">{{ item.user.firstName }} {{ item.user.lastName }}</span>
    </p>
  </div>
  <!--end::Info-->
</div>
</template>

<script>
import { FORMAT_DATE_LOG, FORMAT_TIME } from '../../helpers/validation'

export default {
  name: 'ItemLog',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      FORMAT_DATE_LOG,
      FORMAT_TIME,
    }
  },
  computed: {
    setClass() {
      if (this.item.action === 'create') return 'success'
      if (this.item.action === 'update') return 'info'
      if (this.item.action === 'remove') return 'danger'
      return 'primary'
    },
    setSvg() {
      if (this.item.action === 'create') return 'General/Save'
      if (this.item.action === 'update') return 'General/Update'
      if (this.item.action === 'remove') return 'General/Trash'
      return 'Code/Info-circle'
    },
  },
}
</script>

<style scoped>

</style>
