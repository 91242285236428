<template>
<apexchart
  class="card-rounded-bottom"
  :options="chartOptions"
  :series="[value]"
  type="area"
/>
</template>

<script>
export default {
  name: 'CircleGraph',
  props: {
    value: {
      type: Number,
      default: 100,
      validator(value) {
        return value >= 0 && value <= 100
      },
    },
  },
  data() {
    return {
      chartOptions: {},
    }
  },
  mounted() {
    this.chartOptions = {
      chart: {
        height: 280,
        type: 'radialBar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 15,
            size: '70%',
          },
          dataLabels: {
            showOn: 'always',
            name: {
              show: false,
            },
            value: {
              color: '#111',
              fontSize: '30px',
              show: true,
            },
          },
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      fill: {
        type: 'solid',
        colors: [({ value }) => {
          if (value < 26) {
            return '#F64E60'
          } if (value >= 26 && value < 76) {
            return '#ffc107'
          }
          return '#0BB7AF'
        }],
      },
      stroke: {
        lineCap: 'round',
      },
    }
  },
}
</script>

<style scoped>

</style>
