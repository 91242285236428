<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <!--begin::Title-->
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5 d-none d-sm-block">
        {{ $t('DASHBOARD.BRANCH_STATISTICS.TITLE') }}</h5>
      <!--end::Title-->
    </div>
  </template>
  <template #body>
    <report-table classes="p-0">
      <template #thead v-if="items.length">
        <tr>
          <th scope="col" rowspan="2">
            {{ $t('DASHBOARD.BRANCH_STATISTICS.BRANCH') }}
          </th>
          <th scope="col" rowspan="2">
            {{ $t('DASHBOARD.BRANCH_STATISTICS.ITEMS_QTY') }}
          </th>
          <th scope="col" colspan="3">
            {{ $t('DASHBOARD.BRANCH_STATISTICS.PREPAYMENTS') }}
          </th>
          <th scope="col" colspan="3">
            {{ $t('DASHBOARD.BRANCH_STATISTICS.PAYMENTS') }}
          </th>
          <th scope="col" colspan="3">
            {{ $t('DASHBOARD.BRANCH_STATISTICS.TOTAL') }}
          </th>
        </tr>
        <tr>
          <template v-for="category in
            [
              $t('DASHBOARD.BRANCH_STATISTICS.PREPAYMENTS'),
              $t('DASHBOARD.BRANCH_STATISTICS.PAYMENTS'),
              $t('DASHBOARD.BRANCH_STATISTICS.TOTAL')
            ]"
          >
            <th v-for="(type, index) in ['Bank card', 'Cash', 'Total']" :key="category + index">
              {{ type }}
            </th>
          </template>
        </tr>
      </template>
      <template #tbody v-if="items.length">
        <tr
          v-for="item in items"
          :key="item.branch_name"
        >
          {{ add(`items_count`, item.items_count) }}
          {{ add(`prepayment_card`, item.prepayment_card) }}
          {{ add(`prepayment_cash`, item.prepayment_cash) }}
          {{ add(`prepayment_total`, item.prepayment_total) }}
          {{ add(`payment_card`, item.payment_card) }}
          {{ add(`payment_cash`, item.payment_cash) }}
          {{ add(`payment_total`, item.payment_total) }}
          {{ add(`total_card`, item.total_card) }}
          {{ add(`total_cash`, item.total_cash) }}
          {{ add(`total`, item.total) }}

          <td>
            {{ item.branch_name }}
          </td>
          <td>
            {{ item.items_count }}
          </td>
          <td>
            {{ item.prepayment_card | centsToDollars }}
          </td>
          <td>
            {{ item.prepayment_cash | centsToDollars }}
          </td>
          <td>
            {{ item.prepayment_total | centsToDollars }}
          </td>
          <td>
            {{ item.payment_card | centsToDollars }}
          </td>
          <td>
            {{ item.payment_cash | centsToDollars }}
          </td>
          <td>
            {{ item.payment_total | centsToDollars }}
          </td>
          <td>
            {{ item.total_card | centsToDollars }}
          </td>
          <td>
            {{ item.total_cash | centsToDollars }}
          </td>
          <td>
            {{ item.total | centsToDollars }}
          </td>
        </tr>
        <tr class="text-primary">
          <td>{{ $t('REPORTS.TOTAL') }}</td>
          <td>{{ stored(`items_count`) }}</td>
          <td>{{ stored(`prepayment_card`) | centsToDollars }}</td>
          <td>{{ stored(`prepayment_cash`) | centsToDollars }}</td>
          <td>{{ stored(`prepayment_total`) | centsToDollars }}</td>
          <td>{{ stored(`payment_card`) | centsToDollars }}</td>
          <td>{{ stored(`payment_cash`) | centsToDollars }}</td>
          <td>{{ stored(`payment_total`) | centsToDollars }}</td>
          <td>{{ stored(`total_card`) | centsToDollars }}</td>
          <td>{{ stored(`total_cash`) | centsToDollars }}</td>
          <td>{{ stored(`total`) | centsToDollars }}</td>
        </tr>
      </template>
      <template #tbody v-else>
        <tr>
          <td scope="col" class="text-center">{{ $t('REPORTS.NO_DATA') }}</td>
        </tr>
      </template>
    </report-table>
  </template>
</KTCard>
</template>

<script>
import Repo from '@/core/repository/company/dashboardStats'
import qs from 'qs'
import KTCard from '../../view/content/Card'
import ReportTable from '../report/table/Table.vue'

export default {
  name: 'BranchStats',
  components: {
    KTCard,
    ReportTable,
  },
  data() {
    return {
      params: {
        period: {
          from: '',
          to: '',
        },
      },
      loading: false,
      items: [],
      loopStorage: {},
      fields: [
        {
          key: 'number',
          label: this.$t('DASHBOARD.BRANCH_STATISTICS.BRANCH'),
          sortable: false,
        },
        {
          key: 'client',
          label: this.$t('DASHBOARD.BRANCH_STATISTICS.ITEMS_QTY'),
          class: 'text-center',
          sortable: false,
        },
        {
          key: 'totalCost',
          label: this.$t('DASHBOARD.BRANCH_STATISTICS.PREPAYMENTS'),
          class: 'text-center',
          sortable: false,
        },
        {
          key: 'finalCost',
          label: this.$t('DASHBOARD.BRANCH_STATISTICS.PAYMENTS'),
          class: 'text-center',
          sortable: false,
        },
        {
          key: 'finalCost',
          label: this.$t('DASHBOARD.BRANCH_STATISTICS.TOTAL'),
          class: 'text-center',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    Repo() {
      return Repo
    },
    imgUrl() {
      return (href) => `${process.env.VUE_APP_BACKEND_ORIGIN}/${href}`
    },
    stored() {
      return (k) => {
        return this.loopStorage[k] || 0
      }
    },
  },
  watch: {
    '$route.query.dateRange': {
      handler() {
        // ждем готовности от бека
        this.fetchData()
      },
      immediate: true,
      deep: true,
    },
    items() {
      this.resetLoopStorage()
    },
  },
  methods: {
    add(k, v) {
      if (this.loopStorage[k] === undefined) {
        this.loopStorage[k] = 0
      }
      this.loopStorage[k] += +v
    },
    get(k) {
      return this.loopStorage[k] || 0
    },
    resetLoopStorage() {
      this.loopStorage = {}
    },
    fetchData() {
      this.loading = true
      let params = { ...this.params }
      if (this.$route.query.dateRange) {
        params = {
          ...params,
          ...qs.parse(this.$route.query.dateRange),
        }
      } else {
        return
      }
      this.Repo.getBranchStats(params).then(({ data }) => {
        this.items = data.payload.report.payload.map((branch) => ({
          ...branch,
          prepayment_total: +branch.prepayment_cash + +branch.prepayment_card,
          payment_total: +branch.payment_cash + +branch.payment_card,
          total: +branch.total_card + +branch.total_cash,
        })).sort((a, b) => b.total - a.total)
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
