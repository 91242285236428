<template>
<DateRangePicker
  v-model="getValue"
  v-bind="$attrs"
  v-on="$listeners"
  controlContainerClass="date-range"
  append-to-body
  single-date-picker="range"
  :opens="opens || getOpens"
  :locale-data="localeData"
  :ranges="defaultRanges"
>
  <template #input="picker">
    <div class="cursor-pointer">
      <slot name="selected" :selected="picker">
        <div class="btn btn-light-primary btn-sm font-weight-bold" style="min-width: 120px;">
          {{ picker.startDate ? $moment(picker.startDate).format('DD MMM, yyyy') : $t('DATEPICKER.NO_DATE_SELECTED') }}
          -
          {{ picker.endDate ? $moment(picker.endDate).format('DD MMM, yyyy') : $t('DATEPICKER.NO_DATE_SELECTED') }}
        </div>
      </slot>
    </div>
  </template>
  <template #ranges="ranges">
    <div class="ranges">
      <ul>
        <li v-for="(range, name) in ranges.ranges" :key="name" @click="ranges.clickRange(range)">
          {{ $t(`DATES.${name}`) }}
        </li>
      </ul>
    </div>
  </template>
  <div slot="footer" slot-scope="data" class="slot">
    <div class="drp-buttons d-flex justify-content-end align-items-center">
      <button
        @click="data.clickCancel"
        type="button"
        class="cancelBtn btn btn-sm btn-light-primary font-weight-normal"
      >
        {{ $t('BASE.BACK') }}
      </button>
      <button
        v-if="!data.in_selection"
        @click="data.clickApply"
        type="button"
        class="applyBtn btn btn-sm btn-primary font-weight-normal"
      >
        {{ $t('FORM.SUBMIT') }}
      </button>
    </div>
  </div>
</DateRangePicker>
</template>
<script>
import { isMobile } from 'mobile-device-detect'
import { FORMAT_DATE } from '@/helpers/validation'

export default {
  name: 'DateRangePickerUI',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: null,
    format: {
      type: String,
      default: 'YYYY-MM-DD HH:mm:ss',
    },
    opens: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      FORMAT_DATE,
      localeData: {
        direction: 'ltr',
        format: 'DD MMM, yyyy',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel',
        daysOfWeek: this.$moment.weekdaysMin(),
        monthNames: this.$moment.months(),
        firstDay: 0,
      },
    }
  },
  computed: {
    getOpens() {
      return isMobile ? 'right' : 'center'
    },
    getValue: {
      get() {
        if (this.value) {
          return {
            startDate: this.value.period.from,
            endDate: this.value.period.to,
          }
        } return {}
      },
      set(val) {
        const date = {
          period: {
            from: '',
            to: '',
          },
        }
        date.period.from = val.startDate ? this.$moment(val.startDate).format(this.format) : val.startDate
        date.period.to = val.endDate ? this.$moment(val.endDate).format(this.format) : val.endDate
        this.$emit('input', date)
      },
    },
    defaultRanges() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      const yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)

      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)
      const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0)

      return {
        TODAY: [today, today],
        YESTERDAY: [yesterday, yesterday],
        MONTH: [thisMonthStart, thisMonthEnd],
        YEAR: [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
        LAST_MONTH: [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
      }
    },
  },
}
</script>
<style lang="scss">
  .calendars-container{
    display: contents !important;
  }
</style>
