<template>
<KTCard>
  <template #title>
    <div class="d-flex align-items-center flex-wrap mr-2">
      <!--begin::Title-->
      <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5 d-none d-sm-block">{{ $t('DASHBOARD.ADMINS_STATISTICS.TITLE') }}</h5>
      <!--end::Title-->
    </div>
  </template>
  <template #body>
    <Table sticky-header :fields="fields" :items="items" :busy.sync="loading">
      <template #cell(number)="data">
        <div style="min-width: 150px">
          <div class="d-flex align-items-center">
            <div class="symbol symbol-50 symbol-light mr-4">
              <span v-if="!data.item.avatar" class="symbol-label">
                <img src="media/svg/avatars/001-boy.svg" alt="" class="h-75 align-self-end">
              </span>
              <span v-else class="symbol-label">
                <img :src="imgUrl(data.item.avatar.hrefThumbnail)" alt="" class="w-100 h-75 align-self-end">
              </span>
            </div>
            <div>
              <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                {{ data.item.firstName }} {{ data.item.lastName }}
              </a>
            </div>
          </div>
        </div>
      </template>
      <template #cell(client)="data">
        <div style="min-width: 150px">
          <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">
            {{ data.item.serviceAndProductsCount }}
          </span>
        </div>
      </template>
      <template #cell(totalCost)="data">
        <div style="min-width: 150px">
          <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">
            {{ data.item.orderCount }}
          </span>
        </div>
      </template>
      <template #cell(finalCost)="data">
        <div style="min-width: 150px">
          <span class="text-dark-75 font-weight-bolder font-size-lg mb-0">
            {{ data.item.orderCostWithDiscount | centsToDollars }}
          </span>
        </div>
      </template>
      <template #cell(actions)="data">
        <div style="min-width: 80px">
          <router-link
            :to="{ name: 'editBusinessUser', params: { id: data.item.uuid }}"
            class="btn btn-light-success font-weight-bolder font-size-sm"
          >
            {{ $t('DASHBOARD.ADMINS_STATISTICS.PROFILE') }}
          </router-link>
        </div>
      </template>
    </Table>
  </template>
</KTCard>
</template>

<script>
import Repo from '@/core/repository/company/dashboardStats'
import qs from 'qs'
import KTCard from '../../view/content/Card'
import Table from '../UI/Table'

export default {
  name: 'UserStats',
  components: {
    KTCard,
    Table,
  },
  data() {
    return {
      params: {
        period: {
          from: '',
          to: '',
        },
      },
      loading: false,
      items: [],
      fields: [
        {
          key: 'number',
          label: this.$t('DASHBOARD.ADMINS_STATISTICS.ADMINS'),
          sortable: false,
        },
        {
          key: 'client',
          label: this.$t('DASHBOARD.ADMINS_STATISTICS.SERVICES_AND_PRODUCTS'),
          class: 'text-center',
          sortable: false,
        },
        {
          key: 'totalCost',
          label: this.$t('DASHBOARD.ADMINS_STATISTICS.ORDER_QTY'),
          class: 'text-center',
          sortable: false,
        },
        {
          key: 'finalCost',
          label: this.$t('DASHBOARD.ADMINS_STATISTICS.ORDER_WITH_DISCOUNT'),
          class: 'text-center',
          sortable: false,
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    Repo() {
      return Repo
    },
    imgUrl() {
      return (href) => `${process.env.VUE_APP_BACKEND_ORIGIN}/${href}`
    },
  },
  watch: {
    '$route.query.dateRange': {
      handler() {
        // ждем готовности от бека
        this.fetchData()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    fetchData() {
      this.loading = true
      let params = { ...this.params }
      if (this.$route.query.dateRange) {
        params = {
          ...params,
          ...qs.parse(this.$route.query.dateRange), 
        }
      } else {
        return
      }
      this.Repo.getUserStats(params).then(({ data }) => {
        this.items = data.payload.report.payload
        this.loading = false
      })
    },
  },
}
</script>

<style scoped>

</style>
